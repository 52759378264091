import React from "react";
import styled from "styled-components";

const LoaderSpinner = styled.span`
	top: 50%;
`;
interface ILoaderProps {
	message?: string;
}
export function Loader(props: ILoaderProps) {
	return (
		<>
			<div className="w-full h-full left-0 fixed inset-y-1/2 mt-4 z-50 flex flex-col items-center">
				<svg className="animate-spin -ml-1 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
					<circle cx="12" cy="12" r="10" stroke="black" strokeWidth="4"></circle>
					<path fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
				</svg>
				{props.message && <span className="mt-2">{props.message}</span>}
			</div>
			<div className="w-full h-full top-0 left-0 fixed bg-white opacity-90 z-40"></div>
		</>
	);
}
