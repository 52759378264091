import styled from "styled-components";

export const PrimaryButton = styled.button`
	padding: 10px;
	background-color: #000;
	color: #fff;
	cursor: pointer;
`;

export const PrimaryButtonSimple = styled.button`
	background-color: #000;
	color: #fff;
	cursor: pointer;
`;
